function hideAllInputs(result: any) {
    Object.values(result).forEach((input: any) => {
        input.visible = false;
    });
    return result;
}

function addNewInputs(result: any, formInputs: any, direction: string) {
    formInputs.forEach((input: any) => {
        if (!result[input.name]) {
            result[input.name] = {
                component: 'input',
                vModel: '',
                visible: input[direction].visible,
                options: {
                    placeholder: input[direction].placeholder,
                    type: input.type,
                    errorMsg: input[direction].error,
                    name: input.name,
                },
            };
            if (result[input.name].options.type === 'radio') {
                result[input.name].options.options = input[direction].placeholder.split(':')[1].split(',').map((item: any, index: number) => {
                    return {
                        label: item,
                        value: item,
                        active: index === 0,
                    }
                });
                result[input.name].vModel = result[input.name].options.options[0].value;
            }
        }
    });
    return result;
}

function updateExistingInputs(result: any, formInputs: any, direction: string) {
    formInputs.forEach((input: any) => {
        if (result[input.name]) {
            result[input.name].visible = input[direction].visible;
            result[input.name].options.placeholder = input[direction].placeholder;
            result[input.name].options.type = input.type;
            result[input.name].options.errorMsg = input[direction].error;
            result[input.name].error = '';
        }
    });
    return result;
}

export function populateFormInputsObject(
    initialFormInputs: any,
    formInputs: any,
    direction: string
) {
    let result = {...initialFormInputs};
    result = hideAllInputs(result);
    result = addNewInputs(result, formInputs, direction);
    result = updateExistingInputs(result, formInputs, direction);
    return result;
}

export function getOptionsFromPairData(pairData: any, fromOrTo: any) {
    if (pairData?.structure?.[fromOrTo]) {
        return pairData?.structure?.[fromOrTo].currency.length >
        pairData?.structure?.[fromOrTo].network.length
            ? pairData?.structure?.[fromOrTo].currency
            : pairData?.structure?.[fromOrTo].network;
    }
    return [];
}
